<template>
 <div class="row">
    <div class="col-12 col-lg-6 c g">
         <div class="card card-body">
            <div class="form-group">
              <label for="">الاسم</label>
              <input type="text"
                class="form-control" v-model="student.name">
            </div>
            <div class="form-group">
              <label for="">رقم الهوية</label>
              <input type="text"
                class="form-control" v-model="student.number">
            </div>
            <div class="form-group">
              <label for="">الجوال <i class="fa fa-plus text-primary" @click="phonespush()"></i></label>
              <div v-for="(find, index) in phones"  :key="index" class="g">
                    <input v-model="find.value" class="form-control" @change="refreshPhones()">
                </div>
            </div>
            <div class="form-group">
              <label for="">اختر مجموعة الاعدادات</label>
              <select class="form-control" v-model="student.group_id">
                <option v-for="group in groups" :key="group._id" :value="group._id">
                    {{ group.title }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="">اختر الصف (<a href="javascript:;" @click="custom = true"><i class="fa fa-plus"></i> اضافة صف/فصل جديد</a>)</label>
              <select class="form-control" v-model="student.classname" @change="cc()" v-if="!custom">
                <option v-for="c in classes" :key="c._id" :value="c.title">
                    {{ c.title }}
                </option>
              </select>
              <input type="text" class="form-control" v-model="student.classname" placeholder="اكتب الصف يدوياً..." v-if="custom">
            </div>
            <div class="form-group">
              <label for="">اختر الفصل</label>
              <select class="form-control" v-model="student.classroom" v-if="!custom">
                <option v-for="c in classrooms" :key="c" :value="c">
                    {{ c }}
                </option>
              </select>
              <input type="text" class="form-control" v-model="student.classroom" placeholder="اكتب الفصل يدوياً..." v-if="custom">
            </div>
            <div class="col-12 text-center g">
                <button class="btn btn-primary" @click="addNowSingle()">
                    <span v-if="!loading">تعديل الطالب الآن</span>
                    <span v-if="loading">جاري الاضافة...</span>
                </button>
            </div>
        </div>
    </div>
 </div>
</template>

<script>
export default {
    data(){
        return {
            loading: true,
            user: JSON.parse(localStorage.getItem('user')),
            step: 0,
            students: [],
            groups: [],
            group_id: "",
            student: {},
            custom: false,
            classes: [],
            classrooms: [],
            phones: [{value:""}]
        }
    },
    created(){
        if(!checkPer("students|students|edit")){
            this.$router.push('/NotPermitted')
            return false;
        }
        var g = this;
        $.post(api + '/user/students/groups', {
            jwt: this.user.jwt
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                g.groups = r.response
                g.group_id = g.groups[0]._id
                g.student.group_id = g.groups[0]._id
                $.post(api + '/user/students/classes', {
                    jwt: g.user.jwt
                }).then(function(r){
                    g.loading = false
                    if(r.status != 100){
                        alert(r.response)
                    }else{
                        g.classes = r.response
                        g.student.classname = g.classes[0]?.title ?? ""
                        g.classrooms = g.classes[0]?.classrooms ?? []
                        g.student.classroom = g.classes[0]?.classrooms[0] ?? ""
                        $.post(api + '/user/students/student', {
                            jwt: g.user.jwt,
                            id: window.location.href.split("edit/")[1]
                        }).then(function(r){
                            g.loading = false
                            if(r.status != 100){
                                alert(r.response, 200)
                            }else{
                                g.student = r.response
                                g.phones = r.response.phones?.map(function(x){
                                    return {
                                        value: x
                                    }
                                })
                                g.cc()
                            }
                        }).fail(function(){
                            g.loading = false
                        })
                    }
                }).fail(function(){
                })
            }
        }).fail(function(){
        })
    },
    methods: {
        cc(){
            var g = this;
            g.classes.forEach(function(a){
                if(g.student.classname == a.title){
                    g.classrooms = a.classrooms
                }
            })
        },
        addNowSingle(){
            this.loading = true
            var g = this;
            $.post(api + '/user/students/edit', {
                jwt: this.user.jwt,
                student: this.student,
                id: window.location.href.split("edit/")[1]
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response, 200)
                }else{
                    alert(r.response, 100)
                    setTimeout(() => {
                        $("#alert-modal___BV_modal_header_ > button").click()
                    }, 3000);
                }
            }).fail(function(){
                g.loading = false
            })
        },
        refreshPhones(){
            this.student.phones = this.phones.map(function(r){
                return r.value
            })
        },
        phonespush(){
            this.phones.push({
                value: ""
            })
        }
    }
}
</script>

<style>
.ii img{
    width: 60% !important;
    border-radius: 20px;
    margin: 0px auto;
}
.ii h4{
    padding: 10px;
}
.ii .card:hover{
    border: 1px solid green;
    background: #fafafa;
    cursor: pointer;
}
</style>
<style scoped>
#progress-wrp {
  border: 1px solid #0099cc;
  padding: 1px;
  position: relative;
  height: 30px;
  border-radius: 3px;
  margin: 10px;
  text-align: left;
  background: #fff;
  box-shadow: inset 1px 3px 6px rgba(0, 0, 0, 0.12);
  display: none;
}

#progress-wrp .progress-bar {
  height: 100%;
  border-radius: 3px;
  background-color: greenyellow;
  width: 0;
  box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.11);
}

#progress-wrp .status {
  top: 3px;
  left: 50%;
  position: absolute;
  display: inline-block;
  color: #000000;
}
</style>